<template>
  <div>
    <h4>Questionnaire Examen clinique</h4>
    <data-table
      :data-source="questions"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-questionnaire-examen-clinique"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>

    <modal
      title="Ajout de questionnaire"
      id="add-questionnaire-examen-clinique"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingQuestion"
      >
        <div
          class="form-group alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label>Section</label>
          <select
            v-select="{placeholder: 'Selectionnez la section'}"
            class="form-control"
            v-model="section"
          >
            <option value="" />
            <option
              :value="s.uid"
              v-for="(s, i) in sections"
              :key="i"
            >
              {{ s.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="">Libelle</label>
          <input
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="">Ordre</label>
              <input
                type="number"
                class="form-control"
                v-model="ordre"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="sexe">Sexe</label>
              <select
                name="sexe"
                id="sexe"
                class="form-control"
                v-select="{placeholder: 'Selectionnez le sexe'}"
                v-model="sexe"
                required
              >
                <option value="" />
                <option
                  v-for="(s, index) in sexes"
                  :value="s.value"
                  :key="index"
                >
                  {{ s.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        

        <div class="row">
          <div class="col-6">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="questionnaire-libre"
                v-model="libre"
              >
              <label
                class="form-check-label"
                for="questionnaire-libre"
              >Réponse libre? </label>
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-check form-switch"
              v-show="!libre"
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="questionnaire-multi-response"
                v-model="multiResponse"
              >
              <label
                class="form-check-label"
                for="questionnaire-multi-response"
              >Réponse multiple? </label>
            </div>
          </div>
        </div>

        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de questionnaire"
      id="update-questionnaire-examen-clinique"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="updatindQuestion"
      >
        <h5> Questions </h5>
        <div class="form-group">
          <label>Section</label>
          <select
            v-select="{placeholder: 'Selectionnez la section'}"
            class="form-control"
            v-model="section"
          >
            <option value="" />
            <option
              :value="s.uid"
              v-for="(s, i) in sections"
              :key="i"
            >
              {{ s.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="">Libelle</label>
          <input
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="">Ordre</label>
              <input
                type="number"
                class="form-control"
                v-model="ordre"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="code">Code</label>
              <input
                type="text"
                class="form-control"
                id="code"
                name="code"
                v-model="code"
              >
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="sexe">Sexe</label>
              <select
                name="sexe"
                id="sexe"
                class="form-control"
                v-select="{placeholder: 'Selectionnez le sexe'}"
                v-model="sexe"
                required
              >
                <option value="" />
                <option
                  v-for="(s, index) in sexes"
                  :value="s.value"
                  :key="index"
                >
                  {{ s.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>
        

        <div class="row">
          <div class="col-6">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="questionnaire-libre"
                v-model="libre"
              >
              <label
                class="form-check-label"
                for="questionnaire-libre"
              >Réponse libre? </label>
            </div>
          </div>
          <div class="col-6">
            <div
              class="form-check form-switch"
              v-show="!libre"
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="questionnaire-multi-response"
                v-model="multiResponse"
              >
              <label
                class="form-check-label"
                for="questionnaire-multi-response"
              >Réponse multiple? </label>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="!libre"
        >
          <div
            class="col-12"
            style="padding-top: 20px; padding-bottom: 40px;"
          >
            <h5 style="margin-bottom: 20px;">
              Réponses
            </h5>
            <a
              href="javascript:void(0)"
              @click.prevent="addResponse"
              class="btn btn-sm btn-success btn-add-response"
            > <i class="icofont icofont-plus" /></a>
            <response-edit
              v-for="(r, i) in response"
              :response="r"
              :key="i"
              :question="selectedObject"
            />
          </div>
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import { libelleComponentSort } from '../../../../components/dataTable/functions'
import ResponseEdit from '../../../../components/admin/sanitaire/questionaire/responseEdit.vue'
import { BOOLEAN_TYPE, COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import { ADD_QUESTION, UPDATE_QUESTION } from '../../../../graphql/sanitaire'
const Response = () => import('../../../../components/admin/sanitaire/questionaire/response.vue')
const Section = () => import('../../../../components/admin/sanitaire/questionaire/section.vue')
const Actions = () => import('../../../../components/admin/sanitaire/questionaire/actions.vue')
export default {
    components: {DataTable, Modal, ResponseEdit},
    data(){
        return {
            section: null,
            libelle: null,
            code: null,
            ordre: null,
            libre: false,
            multiResponse: false,
            has_error: false,
            error_msg: null,
            sexe: null,
            sexes: [{value: 'f', libelle: 'Femelle'}, {value: 'm', libelle: 'Mâle'}, {value: 't', libelle: 'Tout'}],
            response: []
        }
    },
    watch: {
      responses: {
        handler(){
          this.response = [...this.responses].filter(item => item.question === this.selectedObject.uid)
        },
        deep: true
      },
        selectedObject: {
            handler(){
                if(this.selectedObject !== null) {
                    this.section = this.selectedObject.section
                    this.libelle = this.selectedObject.libelle
                    this.code = this.selectedObject.code
                    this.ordre = this.selectedObject.ordre
                    this.libre = this.selectedObject.libre
                    this.multiResponse = this.selectedObject.multiResponse
                    this.response = [...this.responses].filter(item => item.question === this.selectedObject.uid)
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            addPossibleResponse: 'sanitaire/INCREASE_POSSIBLE_RESPONSE',
            removePossibleResponse: 'sanitaire/DECREASE_POSSIBLE_RESPONSE',
            setPossibleResponse: 'sanitaire/SET_POSSIBLE_RESPONSE'
            
        }),
        initForm(){
          this.section = null
          this.code = null
          this.ordre = null
          this.libelle= null
          this.multiResponse= false
          this.libre= false
          this.sexe = null
        },
        addResponse(){
          this.response.push({adding: true, libelle: '', question: this.selectedObject.uid})
        },
        addingQuestion(){
          let data = {
            section: this.section,
            code: this.code,
            ordre: parseInt(this.ordre),
            libelle: this.libelle,
            multiResponse: this.multiResponse,
            libre: this.libre,
            sexe: this.sexe
          }
          this.$apollo.mutate({
                mutation: ADD_QUESTION,
                variables: {
                    "question": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Question ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        updatindQuestion(){
          let data = {
            section: this.section,
            code: this.code,
            ordre: parseInt(this.ordre),
            libelle: this.libelle,
            multiResponse: this.multiResponse,
            libre: this.libre,
            sexe: this.sexe
          }
          this.$apollo.mutate({
                mutation: UPDATE_QUESTION,
                variables: {
                    "question": {
                        ...data
                    },
                    "uid": this.selectedObject.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Question ${this.libelle} updated successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            nbPossibleResponse: 'sanitaire/nbPossibleResponse',
            sections: 'sanitaire/sections',
            questions: 'sanitaire/questions',
            responses: 'sanitaire/responses',
            selectedObject: 'selectedObject',
            
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Section', name: 'section', type: COMPONENT_TYPE, component: Section, sortable: true, function : (order, source)  => libelleComponentSort(order, source, this.sections, 'section')},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Code', name: 'code', type: TEXT_TYPE},
                {label: 'Ordre', name: 'ordre', type: TEXT_TYPE},
                {label: 'Sexe', name: 'sexe', type: TEXT_TYPE},
                {label: 'Libre', name: 'libre', type: BOOLEAN_TYPE},
                {label: 'Multi-réponse', name: 'multiResponse', type: TEXT_TYPE},
                {label: 'Réponses possible', name: 'responses', type: COMPONENT_TYPE, component: Response},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
                
            ]
        },
        
    }
}
</script>

<style lang="scss" scoped>
.btn-add-response{
  position: absolute;
  left: 150px;
  top: 15px;
}
</style>